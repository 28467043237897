export const imports = {
  'GettingStarted.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "getting-started" */ 'GettingStarted.mdx'
    ),
  'README.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "readme" */ 'README.mdx'
    ),
  'src/validate/README.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-validate-readme" */ 'src/validate/README.mdx'
    ),
}
